jQuery(document).ready(function () {
  if (jQuery('.swpm-login-widget-form').length > 0) {
    // HTML FIX for membership plugin
    let username = jQuery('.swpm-username-label')
    let usernameLabel = username.find('label')
    let password = jQuery('.swpm-password-label')
    let passwordLabel = password.find('label')
    jQuery('.swpm-username-input').prepend(usernameLabel)
    username.remove()
    jQuery('.swpm-password-input').prepend(passwordLabel)
    password.remove()
    let checkbox = jQuery('.swpm-remember-me .swpm-remember-checkbox')
    jQuery('.swpm-remember-me').append(checkbox)

    // Label focus class
    if (jQuery('.swpm-username-field').val() != '') {
      jQuery('.swpm-username-field').prev('label').addClass('active')
    }
    if (jQuery('.swpm-password-input').val() != '') {
      jQuery('.swpm-password-input').prev('label').addClass('active')
    }
    jQuery('.swpm-login-widget-form input').on('focusin', function () {
      jQuery(this).prev('label').addClass('active')
    })
    jQuery('.swpm-login-widget-form input').on('focusout', function () {
      if (jQuery(this).val() == '') {
        jQuery(this).prev('label').removeClass('active')
      }
    })
    jQuery('.swpm-remember-checkbox input').on('click', function () {
      jQuery('.swpm-remember-checkbox').toggleClass('checked')
    })
  }

  if (jQuery('#swpm-pw-reset-form').length > 0) {
    // HTML FIX for membership plugin
    let email = jQuery('.swpm-pw-reset-email')
    let emailLabel = email.find('label')
    jQuery('.swpm-pw-reset-email-input').prepend(emailLabel)
    email.remove()

    // Label focus class
    if (jQuery('.swpm-pw-reset-email-input').val() != '') {
      jQuery('.swpm-pw-reset-email-input').prev('label').addClass('active')
    }
    jQuery('#swpm-pw-reset-form input').on('focusin', function () {
      jQuery(this).prev('label').addClass('active')
    })
    jQuery('#swpm-pw-reset-form input').on('focusout', function () {
      if (jQuery(this).val() == '') {
        jQuery(this).prev('label').removeClass('active')
      }
    })
  }
})
