import apiFetch from '@wordpress/api-fetch'

const initialState = {
  isLoading: false,
  isLoaded: true,
  error: null,
  postsArr: [],
  termsArr: [],
  totalCount: null,
  totalPages: null,
  activePage: 1,
  activeTerm: 'all',
}

const LOAD_GRID_DATA = 'LOAD_GRID_DATA'
const LOAD_GRID_DATA_SUCCESS = 'LOAD_GRID_DATA_SUCCESS'
const LOAD_GRID_DATA_FAILED = 'LOAD_GRID_DATA_FAILED'
const LOAD_POST_TERMS = 'LOAD_POST_TERMS'
const LOAD_POST_TERMS_SUCCESS = 'LOAD_POST_SUCCESS'
const LOAD_POST_TERMS_FAILED = 'LOAD_POST_FAILED'

export const lang = () => {
  let lang = document.getElementsByTagName('html')[0].lang
  if (lang === 'en-US') {
    lang = 'en'
  } else {
    lang = 'de'
  }
  return lang
}

export default (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case LOAD_POST_TERMS:
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
      }
    case LOAD_POST_TERMS_SUCCESS:
      return {
        ...state,
        termsArr: payload,
        isLoading: false,
        isLoaded: true,
      }

    case LOAD_POST_TERMS_FAILED:
      return {
        ...state,
        isLoaded: false,
        error: true,
      }
    case LOAD_GRID_DATA:
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
      }

    case LOAD_GRID_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        totalCount: payload.totalCount,
        totalPages: payload.totalPages,
        postsArr: payload.postsArr,
        activePage: payload.activePage,
        activeTerm: payload.activeTerm,
      }

    case LOAD_GRID_DATA_FAILED:
      return {
        ...state,
        isLoading: false,
        error: payload,
      }

    default:
      return state
  }
}

export const loadPostTerms = () => (dispatch, getState) => {
  dispatch({ type: LOAD_POST_TERMS })
  const currentTerms = [...getState().postsArchive.termsArr]

  if (currentTerms && currentTerms.length > 0) {
    const payload = currentTerms
    dispatch({
      type: LOAD_POST_TERMS_SUCCESS,
      payload,
    })
    return
  }

  const path = `/wp-json/wp/v2/categories?lang=${lang()}`

  apiFetch({ path })
    .then(response => {
      const payload = response.map(term => {
        return {
          id: term.id,
          name: term.name,
        }
      })
      dispatch({
        type: LOAD_POST_TERMS_SUCCESS,
        payload,
      })
    })
    .catch(error => {
      console.log(error)
      dispatch({
        type: LOAD_POST_TERMS_FAILED,
      })
    })
}

export const loadPosts = (perPage = 12, page = 1, exclude, categoryId) => (
  dispatch,
  getState
) => {
  const currentState = { ...getState().postsArchive }
  const payload = {}
  payload.activeTerm = categoryId ? categoryId : currentState.activeTerm

  dispatch({ type: LOAD_GRID_DATA })

  const urlParams = new URLSearchParams({
    module: 'post_teaser',
    per_page: perPage ? perPage : 3,
    page,
    lang: lang(),
  })

  if (payload.activeTerm && payload.activeTerm !== 'all')
    urlParams.append('categories', payload.activeTerm)
  if (exclude) urlParams.append('exclude', exclude.join(','))

  const path = `/wp-json/wp/v2/posts?${urlParams.toString()}`

  fetch(path)
    .then(resp => {
      payload.totalCount = parseInt(resp.headers.get('X-WP-Total'))
      payload.totalPages = parseInt(resp.headers.get('X-WP-TotalPages'))
      return resp.json()
    })
    .then(json => {
      if (json && json.length) {
        payload.postsArr = json.map(post => {
          return {
            id: post.id,
            title: post.title.rendered,
            img: post.imgOutput,
            permalink: post.link,
            text: post.customExcerpt,
            eventDate: post.eventDate,
          }
        })
        payload.isLoaded = true
        payload.isLoading = false
        payload.activePage = page

        dispatch({ type: LOAD_GRID_DATA_SUCCESS, payload })
      }
    })
    .catch(error => {
      const payload = error
      dispatch({ type: LOAD_GRID_DATA_FAILED, payload })
    })
}

// export const filterPosts = termID => (dispatch, getState) => {
//   const allPosts = getState().postsArchive.postsArr
//   const newPosts = allPosts.filter(post => post.id ===)
// }
