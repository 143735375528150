/* global System */
// register component(s) with react habitat

export default {
  HeroSection: () =>
    System.import(
      /* webpackChunkName: "NewReactVC" */
      './data'
    ),
}
