const initialState = {
  posts: [],
  isLoading: false,
  isLoaded: true,
  error: null,
}

const LOAD_HERO_DATA = 'LOAD_HERO_DATA'
const LOAD_HERO_DATA_SUCCESS = 'LOAD_HERO_DATA_SUCCESS'
const LOAD_HERO_DATA_FAILED = 'LOAD_HERO_DATA_FAILED'

export default (state = initialState, action) => {
  const { type, payload, error } = action

  switch (type) {
    case LOAD_HERO_DATA:
      return {
        ...state,
        isLoading: true,
      }

    case LOAD_HERO_DATA_SUCCESS:
      return {
        ...state,
        posts: payload,
        isLoading: false,
        isLoaded: true,
      }

    case LOAD_HERO_DATA_FAILED:
      return {
        ...state,
        error,
        isLoading: true,
        isLoaded: false,
      }
    default:
      return state
  }
}
