export default {
  init() {
    // JavaScript to be fired on all pages
    const headerWrapper = $('.header-wrapper')
    const disruptorButton = $('.disruptor__btn')

    $(window).load(() => {
      anchorAnimation()
    })

    $(window).scroll(() => {
      stickyHeader(headerWrapper)
    })

    stickyHeader(headerWrapper)
    disruptorClose(disruptorButton)
    formLabelAnimation()
    setTimeout(kontaktUtmCampaign, 1000)
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
}

function stickyHeader(headerWrapper) {
  const headerNav = $('.header__wrap'),
    headerNavOffsetTop = parseInt(headerWrapper.offset().top),
    scrollTop = $(window).scrollTop()

  if (scrollTop > headerNavOffsetTop) {
    headerWrapper.css('padding-top', parseInt(headerNav.outerHeight()))
    headerNav.addClass('sticky')
  } else {
    headerWrapper.css('padding-top', 0)
    headerNav.removeClass('sticky')
  }
}

function disruptorClose(disruptorButton) {
  disruptorButton.each(function (index) {
    const disruptor = $(this).closest('.disruptor')
    if (disruptor) {
      const disruptorHide = localStorage.getItem('Closed-disruptor-' + index)
      if (!disruptorHide) {
        disruptor.addClass('active')
      }

      $(this).on('click', function () {
        disruptor.removeClass('active')
        localStorage.setItem('Closed-disruptor-' + index, true)
      })
    }
  })
}

function formLabelAnimation() {
  $(
    'input:not([type="checkbox"]):not([type="radio"]),textarea,select',
    '.gfield'
  ).each(function () {
    $(this).attr('placeholder', '')
    if (!$(this).closest('.gfield').hasClass('active')) {
      if ($(this).val() !== '') {
        $(this).addClass('active')
        $(this)
          .closest('.gfield')
          .addClass('active')
          .find('.gfield_label')
          .addClass('active')
      }
      $(this).on('focusin', function () {
        $(this).closest('.gfield').find('.gfield_label').addClass('active')
      })

      $(this).on('focusout', function () {
        if (!$(this).val()) {
          $(this).closest('.gfield').find('.gfield_label').removeClass('active')
        }
      })
    }

    $(this).on('focusin', function () {
      $(this).closest('.gfield').addClass('focusin')
    })

    $(this).on('focusout', function () {
      $(this).closest('.gfield').removeClass('focusin')
    })
  })

  $('.ginput_complex').each(function () {
    const inputs = $(this).find('input')

    inputs.each(function () {
      if ($(this).val() !== '') {
        $(this).addClass('active')
      }
    })

    inputs.on('input focusin focusout change', function () {
      if ($(this).val() !== '' || $(this).is(':focus')) {
        $(this).addClass('active')
      } else {
        $(this).removeClass('active')
      }
    })
  })
}

function getUrlParameter(sParam) {
  var sPageURL = window.location.search.substring(1),
    sURLVariables = sPageURL.split('&'),
    sParameterName,
    i

  for (i = 0; i < sURLVariables.length; i++) {
    sParameterName = sURLVariables[i].split('=')

    if (sParameterName[0] === sParam) {
      return sParameterName[1] === undefined
        ? true
        : decodeURIComponent(sParameterName[1])
    }
  }
  return false
}

function kontaktUtmCampaign() {
  $('a').attr('href', function (i, href) {
    const utmCampaign = getUrlParameter('utm_campaign')

    if (utmCampaign && href.includes('/kontakt/')) {
      return (
        href +
        (href.indexOf('?') != -1
          ? `&utm_campaign=${utmCampaign}`
          : `?utm_campaign=${utmCampaign}`)
      )
    }
  })
}

function anchorAnimation() {
  if (window.location.hash) {
    var headerH = $(document).find('.header__wrap').outerHeight()
    $('html,body').scrollTop($(window.location.hash).offset().top - headerH)
  }
  $('a[href^=#]').on('click', function (e) {
    e.preventDefault()
    var dest = $(this).attr('href')
    var headerH = $(document).find('.header__wrap').outerHeight()
    $('html,body').animate(
      { scrollTop: $(dest).offset().top - headerH },
      'slow'
    )
  })
}
