import { combineReducers } from 'redux'
import browser from './browser'
import router from './router'
import postsArchive from './postsArchive'
import archiveHero from './archiveHero'

const appReducer = combineReducers({
  browser,
  location: router,
  postsArchive,
  archiveHero,
})

export default appReducer
