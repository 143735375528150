/* global System */
// register component(s) with react habitat

export default {
  PortfolioItem: () =>
    System.import(
      /* webpackChunkName: "NewReactVC" */
      './PortfolioItem'
    ),
}
